
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { useModals } from '@/compositions/modals'
import { useBottomBar } from '@/compositions/bottomBar'
import SettingsAutoResponders from '@/components/pages/shared/settings/SettingsAutoResponders.vue'

export default defineComponent({
  components: {
    SettingsAutoResponders,
    DetailsHero,
    InfoCard,
    PageContent,
  },
  setup() {
    const { openModal } = useModals()
    const { provideData, getDiscardFunc } = useBottomBar()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Auto-responder', url: '' },
    ]

    const enabled = ref(false)
    const defaultEnabled = enabled.value

    watch(() => enabled.value, (newValue) => {
      newValue !== defaultEnabled ? provideData(1) : getDiscardFunc.value()
    })

    const restoreEnabled = () => {
      enabled.value = defaultEnabled
    }

    onMounted(() => {
      provideData(0, restoreEnabled)
    })

    onBeforeUnmount(() => {
      getDiscardFunc.value()
    })

    return {
      openModal,
      breadcrumbs,
      enabled,
    }
  },
})
