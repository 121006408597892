
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import LogoSelect from '@/components/pages/shared/LogoSelect.vue'
import { useModes } from '@/compositions/modes'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    LogoSelect,
    TmDropdownItem,
    TmDropdown,
    TmButton,
    TmFormLine,
  },
  setup() {
    const { openModal } = useModals()
    const { isEmptyMode } = useModes()
    const outsideHours = ref(true)
    const withinHours = ref(true)
    const emailSubject = ref(isEmptyMode.value ? '' : 'Ticket received: {{ticket_id}} - {{ticket_subject}}')
    const headline = ref(isEmptyMode.value ? '' : 'Ticket received: {{ticket_id}} - {{ticket_subject}}')
    const footer = ref(isEmptyMode.value ? '' : 'This email was sent to you by Textmagic Ltd.\n34 Thame Road, Great Haseley, OX44 7JF, United Kingdom')
    const emailBody = ref(isEmptyMode.value ? '' : 'Hello {{ticket.requester.name}},\n\nWe have recieved your request and a ticket has been created.\nOne of our agents will be reviewing your request and send you a personal response (usually within 24 hours).\n\nYour ticket ID is {{ticket.id.number}}\n\n{{agent_signature}}')

    const feedbackTextsModal = ref({
      footerMessage: 'This email was sent to you by Textmagic.\n34 Thame Road, Great Haseley, OX44 7JF, United Kingdom',
      emailSubject: 'Ticket updated: 394588 - I need help to reset my password',
      name: 'John',
      surveyHeading: 'We are working on your request',
      introMessage: 'We have recieved your request and a ticket has been created.<br/>One of our agents will be reviewing your request and send you a personal response (usually within 24 hours).<br/><br/>Your ticket ID is <span class="font-weight-semi-bold">884798</span>.<br/><br/>Thank you,<br/>Textmagic team',
    })
    const openEmailPreview = () => {
      openModal('emailPreview', {
        modalTitle: 'Auto-responder email preview',
        emailType: 'Ticket updated',
        feedbackTextsModal: feedbackTextsModal.value,
      })
    }
    const tags = [
      'First name',
      'Last name',
      'Phone',
      'Email',
      'Company name',
      'Test field',
    ]

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.assignment,
      editorBtns.ai,
    ]

    return {
      openModal,
      openEmailPreview,
      tags,
      footer,
      emailBody,
      headline,
      emailSubject,
      withinHours,
      outsideHours,
      btnsList,
    }
  },
})
